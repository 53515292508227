import {
  Navigate,
  redirect,
  useLoaderData,
  useSearchParams,
} from '@remix-run/react';
import { type ClientLoaderFunctionArgs } from '@remix-run/react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { ConfirmCancelModalProvider } from '../components/ConfirmCancelModalContext';
import { useMyOrganization } from '../components/Organization/hooks/organization';
import { PaymentUtils } from '../components/Payment/utils';
import { SubscriptionPayment } from '../components/Product/SubscriptionPayment';
import { ProductSearchParam, ProductUtils } from '../components/Product/utils';
import { ProvidersList } from '../components/ProvidersList';
import { PublicHomeLayout } from '../components/PublicHome/Layout';
import { UserContextProvider } from '../components/UserContext';
import config from '../config';
import { useBootIntercom } from '../hooks/useBootIntercom';
import { useInstance } from '../hooks/useInstance';
import { useTitle } from '../hooks/useTitle';
import { apiService } from '../services/api-service';
import { store } from '../store/configureStore';
import { booleanify, makeTitle } from '../utils/common';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const params = new URL(action.request.url).searchParams;
  const productId = params.get(ProductSearchParam.TargetProduct);
  const priceId = params.get(ProductSearchParam.TargetPrice);
  const headcount = PaymentUtils.ParseHeadcount(params.get('headcount'));
  const trial = booleanify(params.get(ProductSearchParam.Trial));
  const redirectTo =
    params.get(ProductSearchParam.RedirectTo) || '/settings/billing';

  const resp = await apiService.product.getPublicProducts();
  const product = resp.data.published.find((p) => p.id === productId);
  const price = product?.prices?.find((p) => p.id === priceId);
  if (!product || !price) {
    throw redirect('/subscription/change');
  }

  return {
    product,
    price,
    headcount: headcount || price.maxSeats,
    trial,
    redirectTo,
  };
};

function ComponentInternal() {
  const { product, price, trial, headcount, redirectTo } =
    useLoaderData<typeof clientLoader>();

  const organization = useMyOrganization();
  const [, setSearchParams] = useSearchParams();

  if (!organization) return <Navigate to={redirectTo} replace />;

  return (
    <SubscriptionPayment
      organization={organization}
      product={product}
      price={price}
      headcount={headcount}
      trial={trial}
      returnUrl={redirectTo}
      fixedSidebar={false}
      onHeadcountChange={(headcount) => {
        const newPrice =
          ProductUtils.FindCheapestPrice(product, {
            headcount,
            billingInterval: price.billingInterval,
          }) || price;

        setSearchParams(
          (prev) => {
            const searchParams = new URLSearchParams(prev);
            searchParams.set('headcount', headcount.toString());
            searchParams.set(ProductSearchParam.TargetPrice, newPrice.id);
            return searchParams.toString();
          },
          {
            replace: true,
          }
        );
      }}
    />
  );
}

function Bootstrap() {
  useBootIntercom(useInstance(() => ['/subscription/change']));

  return null;
}

export function Component() {
  useTitle(makeTitle('Subscription'));
  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <IntercomProvider appId={config.misc.intercomAppId} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <PublicHomeLayout>
        <div className='relative flex-1 min-h-0 overflow-y-auto scrollbar flex justify-center items-center'>
          <ComponentInternal />
        </div>

        <Bootstrap />
      </PublicHomeLayout>
    </ProvidersList>
  );
}
